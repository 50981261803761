import React from 'react';
import {
    Button,
    Window as Windows95Window,
    WindowContent,
    WindowHeader
} from 'react95';

import Game from '../Game/Game';
import './window.scss';

export default function MainWindow ({ ...props })
{
    const [error, setError] = React.useState(false);
    const [shouldLoad, setShouldLoad] = React.useState(true);

    React.useEffect(() => {
        localStorage.setItem('shouldLoad', JSON.stringify(shouldLoad));
    }, [shouldLoad]);
    
    const handleReset = () => {
        setShouldLoad(false);
        window.location.reload();
    }

    return (
        <>
            <Windows95Window className="window">
                <WindowHeader active={!error} className="window-header">
                    <span>Z8 Water Treatment Plant Override</span>
                    <Button onClick={ () => { if (window.confirm('Are you sure you want to start again?')) { handleReset() } } }>
                        <span className="close-icon"/>
                    </Button>
                </WindowHeader>
                <WindowContent style={{ height: '520px'}}>
                    <Game setError={setError}/>
                </WindowContent>
            </Windows95Window>
            { error ?
                <Windows95Window className='window' style={{ position: 'fixed', top: '35%', right: 'calc(50% - 200px)', width: '400px', height: 'auto' }}>
                    <WindowHeader className='window-header'>
                        <span></span>
                        <Button onClick={ () => { setError(false) }}>
                            <span className='close-icon' />
                        </Button>
                    </WindowHeader>
                    <WindowContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ textAlign: 'center' }}>
                            Oh no, we seem to have run out of time. Whilst you were working I managed to hack into the devices timer. I've frozen it for the time being, hopefully I can hold it for long enough to allow you to disarm it. It might mean we miss out on catching Yakov, but it's for the good of the people of Molvaria!
                        </p>
                        <Button onClick={ () => { setError(false) }} style={{ width: 'min-content', marginTop: '30px'}}>
                            Ok
                        </Button>
                    </WindowContent>
                </Windows95Window>
            : '' }
        </>
    )
}
